<template>
  <v-row>
    <v-col cols="12" lg="12">
      <v-card>
        <v-row>
          <v-col cols="12" lg="12">
            <h2 class="font-weight-bold mb-0">Summary</h2>
            <span class="font-weight-bold mb-0 py-2">
              This API request allows you to get an estimated delivery fee to
              pay, it takes the company location and destination header which
              are used to compute the distance and estimate the fee that can be
              incured after delivery. The request URL for this API is
              <a>https://logistic.groupngs.com/api/</a>. <br /><br />Note: Only
              POST requests are accepted by this API
            </span>

            <v-divider></v-divider>

            <h3 class="font-weight-light mb-0">
              Request body
            </h3>

            <!--Request body-->
            <div
              class="mt-2"
              v-for="requestHeader in requestHeaders"
              :key="requestHeader.id"
            >
              <v-divider></v-divider>
              <v-row class="mt-2">
                <v-col cols="12" lg="3">{{ requestHeader.label }} </v-col>
                <v-col cols="12" lg="2">{{ requestHeader.dataType }} </v-col>
                <v-col cols="12" lg="7">{{ requestHeader.description }}</v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>

        <h3 class="font-weight-light mb-0">
          Code samples
        </h3>
        <v-card class="mt-5">
          <v-tabs v-model="selectedLanguage" background-color="primary" dark>
            <v-tab v-for="language in programmingLanguages" :key="language.id"
              ><v-icon>{{ language.icon }}</v-icon>
              <!-- {{ language.text }} -->
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="selectedLanguage">
            <!--Bank deposit-->
            <v-tab-item>
              <v-card flat>
                <v-card-text class="pa-5">
                  <!--Code here-->
                  <v-row>
                    <v-col cols="12">
                      <pre>
                          <code v-html="javaScriptSample">
                          </code>
                        </pre>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!--Visa/master card-->
            <v-tab-item>
              <v-card flat>
                <v-card-text class="pa-5">
                  <!--code here-->
                  <v-row>
                    <v-col cols="12">
                      <pre>
                          <code v-html="phpSample">
                          </code>
                        </pre>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!--Mobile Money-->
            <v-tab-item>
              <v-card flat>
                <v-card-text class="pa-5">
                  <!--code here-->
                  <v-row>
                    <v-col cols="12">
                      <pre>
                          <code v-html="javaSample">
                          </code>
                        </pre>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!--Mobile Money-->
            <v-tab-item>
              <v-card flat>
                <v-card-text class="pa-5">
                  <!--code here-->
                  <v-row>
                    <v-col cols="12">
                      <pre>
                          <code v-html="pythonSample">
                          </code>
                        </pre>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>

        <h3 class="font-weight-light mb-0">
          Expected Responses
        </h3>
        <v-card class="mt-5">
          <v-tabs v-model="selectedResponse" background-color="primary" dark>
            <v-tab v-for="apiResponse in apiResponses" :key="apiResponse.id">
              {{ apiResponse.text }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="selectedResponse">
            <!--Bank deposit-->
            <v-tab-item>
              <v-card flat>
                <v-card-text class="pa-5">
                  <!--Code here-->
                  <v-row>
                    <v-col cols="12">
                      <pre>
                          <code v-html="successfulResponse">
                          </code>
                        </pre>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!--Visa/master card-->
            <v-tab-item>
              <v-card flat>
                <v-card-text class="pa-5">
                  <!--code here-->
                  <v-row>
                    <v-col cols="12">
                      <pre>
                          <code v-html="failedResponse">
                          </code>
                        </pre>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  template: "#documentation",
  data: () => ({
    selectedLanguage: {},
    selectedResponse: {},
    requestHeaders: [
      {
        id: 1,
        label: "origin",
        dataType: "Array",
        description:
          "(REQUIRED*) Latitude and Longitude (Eg. '[0.315133, 32.576353]') of origin point/position.",
      },
      {
        id: 2,
        label: "destination",
        dataType: "Array",
        description:
          "(REQUIRED*) Latitude and Longitude (Eg. '[0.315133, 32.576353]') of destination point/position.",
      },
      {
        id: 5,
        label: "privateKey",
        dataType: "String",
        description:
          "(REQUIRED*) Basic authentication header containing the Private Key obtained from the dashboard credentials.",
      },
      {
        id: 6,
        label: "countryCode",
        dataType: "String",
        description: "(REQUIRED*) Country code Name i.e UG, KE, TZ etc, ",
      },
      {
        id: 8,
        label: "vehicleType",
        dataType: "String",
        description:
          "(REQUIRED*) The type of carrier to take the package (Eg. 'DELIVERY_BIKE, DELIVERY_10_20_TON_TRUCK, DELIVERY_3_TON_TRUCK, DELIVERY_5_10_TON_TRUCK, DELIVERY_BIKE_BOX, DELIVERY_CAB, DELIVERY_PICKUP_TRUCK, DELIVERY_PICKUP_TRUCK_OPENED, DELIVERY_TRUCK')",
      },
      {
        id: 8,
        label: "action",
        dataType: "String",
        description: "estimateDeliveryFeesAndTime",
      },
    ],

    phpSample: `
    <?php
      require_once 'HTTP/Request2.php';

      $request = new Http_Request2('https://logistic.groupngs.com/api/');
      $url = $request->getUrl();

      $headers = array(
          // Request headers
          'Authorization' => '',
          'X-Target-Environment' => '',
          'X-Callback-Url' => '',
          'Content-Type' => 'application/json',
      );

      $request->setHeader($headers);

      $parameters = array(
          // Request parameters
      );

      $url->setQueryVariables($parameters);

      $request->setMethod(HTTP_Request2::METHOD_POST);

      // Request body
      $request->setBody("{body}");

      try
      {
          $response = $request->send();
          echo $response->getBody();
      }
      catch (HttpException $ex)
      {
          echo $ex;
      }

      ?>`,

    javaSample: `
    // // This sample uses the Apache HTTP client from HTTP Components (http://hc.apache.org/httpcomponents-client-ga/)
    import java.net.URI;
    import org.apache.http.HttpEntity;
    import org.apache.http.HttpResponse;
    import org.apache.http.client.HttpClient;
    import org.apache.http.client.methods.HttpGet;
    import org.apache.http.client.utils.URIBuilder;
    import org.apache.http.impl.client.HttpClients;
    import org.apache.http.util.EntityUtils;

    public class JavaSample 
    {
        public static void main(String[] args) 
        {
            HttpClient httpclient = HttpClients.createDefault();

            try
            {
                URIBuilder builder = new URIBuilder("https://logistic.groupngs.com/api/");


                URI uri = builder.build();
                HttpPost request = new HttpPost(uri);
                request.setHeader("Authorization", "");
                request.setHeader("X-Target-Environment", "");
                request.setHeader("X-Callback-Url", "");
                request.setHeader("Content-Type", "application/json");


                // Request body
                StringEntity reqEntity = new StringEntity("{body}");
                request.setEntity(reqEntity);

                HttpResponse response = httpclient.execute(request);
                HttpEntity entity = response.getEntity();

                if (entity != null) 
                {
                    System.out.println(EntityUtils.toString(entity));
                }
            }
            catch (Exception e)
            {
                System.out.println(e.getMessage());
            }
        }
    }
`,

    pythonSample: `
    ########### Python 2.7 #############
    import httplib, urllib, base64

    headers = {
        # Request headers
        'Authorization': '',
        'X-Target-Environment': '',
        'X-Callback-Url': '',
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': '{subscription key}',
    }

    params = urllib.urlencode({
    })

    try:
        conn = httplib.HTTPSConnection('https://logistic.groupngs.com/api/')
        conn.request("POST", params, "{body}", headers)
        response = conn.getresponse()
        data = response.read()
        print(data)
        conn.close()
    except Exception as e:
        print("[Errno {0}] {1}".format(e.errno, e.strerror))

    ####################################

    ########### Python 3.2 #############
    import http.client, urllib.request, urllib.parse, urllib.error, base64

    headers = {
        # Request headers
        'Authorization': '',
        'X-Target-Environment': '',
        'X-Callback-Url': '',
        'Content-Type': 'application/json',
    }

    params = urllib.parse.urlencode({
    })

    try:
        conn = http.client.HTTPSConnection('https://logistic.groupngs.com/api/')
        conn.request("POST", params, "{body}", headers)
        response = conn.getresponse()
        data = response.read()
        print(data)
        conn.close()
    except Exception as e:
        print("[Errno {0}] {1}".format(e.errno, e.strerror))

    ####################################`,

    javaScriptSample: `
        $(function() {
            var params = {
                // Request parameters
            };
          
            $.ajax({
                url: "https://logistic.groupngs.com/api/?" + $.param(params),
                beforeSend: function(xhrObj){
                    // Request headers
                    xhrObj.setRequestHeader("Authorization","");
                    xhrObj.setRequestHeader("X-Target-Environment","");
                    xhrObj.setRequestHeader("X-Callback-Url","");
                    xhrObj.setRequestHeader("Content-Type","application/json");
                    xhrObj.setRequestHeader("Ocp-Apim-Subscription-Key","{subscription key}");
                },
                type: "POST",
                // Request body
                data: "{body}",
            })
            .done(function(data) {
                alert("success");
            })
            .fail(function() {
                alert("error");
            });
        });`,

    programmingLanguages: [
      {
        text: "JavaScript",
        value: "nodejs",
        icon: "mdi mdi-language-javascript",
      },
      {
        text: "PHP",
        value: "php",
        icon: "mdi-language-php",
      },
      {
        text: "Java",
        value: "java",
        icon: "mdi-language-java",
      },
      {
        text: "Python",
        value: "python",
        icon: "mdi-language-python",
      },
    ],

    apiResponses: [
      {
        text: "Successful response",
        value: "success",
      },
      {
        text: "Failed response",
        value: "fail",
      },
    ],

    successfulResponse: `
    {
      "distance": "129332.5",
      "estimatedFee": "13000",
      "message": "OK",
      "status": "200",
    }`,

    failedResponse: `
    {
      "message": "",
      "statis": "500",
    }`,
  }),
};
</script>
